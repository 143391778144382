<template>
  <template v-if="tooltipMsg">
    <TopbarTooltip :message="tooltipMsg">
      <button
        type="button"
        :class="classes"
        class="text-center font-medium border border-1_5 rounded-lg"
        v-bind="$attrs"
        :readonly="props.readonly"
        @click="onClick"
      >
        <slot></slot>
      </button>
    </TopbarTooltip>
  </template>
  <template v-else>
    <button
      type="button"
      :class="classes"
      class="text-center font-medium border border-1_5 rounded-lg"
      v-bind="$attrs"
      :readonly="props.readonly"
      @click="onClick"
    >
      <slot></slot>
    </button>
  </template>
</template>
<script lang="ts" setup>
import { computed } from 'vue'
import TopbarTooltip from '../../../topbar/components/TopbarTooltip.vue'

const props = withDefaults(
  defineProps<{
    primary?: boolean
    tertiary?: boolean
    textSm?: boolean
    padding?: boolean
    readonly?: boolean
    tooltipMsg?: string
    icon?: boolean
  }>(),
  {
    primary: false,
    tertiary: false,
    textSm: false,
    padding: false
  }
)

const emit = defineEmits(['action'])

const onClick = (event: MouseEvent) => {
  if (props.readonly) {
    event.preventDefault()
    event.stopPropagation()
    return true
  } else emit('action')
}

const classes = computed(() => {
  let result: string[] = []

  if (props.primary) {
    if (!props.readonly) {
      result.push(
        'border-transparent',
        'bg-primary',
        'hover:bg-primary-80',
        'text-primary-10',
        'shadow-button',
        'focus-visible:outline',
        'focus-visible:outline-1',
        'focus-visible:outline-offset-2',
        'focus-visible:outline-primary'
      )
    } else {
      result.push(
        'cursor-custom',
        'cursor-not-allowed',
        'border-transparent',
        'bg-primary-50',
        'text-primary-40',
        'focus-visible:outline-0'
      )
    }
  } else {
    if (!props.readonly) {
      result.push(
        'border-primary-80',
        'text-primary',
        'hover:bg-primary-30',
        'focus-visible:border-transparent',
        'focus-visible:outline',
        'focus-visible:outline-1',
        'focus-visible:outline-offset-1',
        'focus-visible:outline-primary'
      )
    } else {
      result.push(
        'cursor-custom',
        'cursor-not-allowed',
        'border-gray-200',
        'text-gray-200',
        'focus-visible:outline-0'
      )
    }
  }

  if (props.textSm) {
    result.push('text-xs px-3 py-1.5')
  } else {
    if (props.icon) {
      result.push('w-9 h-9')
    } else {
      result.push('text-sm px-4 py-2')
    }
  }

  if (props.padding) result.push('')

  return result
})
</script>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'DButton',
  inheritAttrs: false
})
</script>
